<template>
    <div class="mt-1" v-on:keyup.enter="forceRefreshTable">
        <div v-if="customFilter !== null">
            <component :is="customFilter"
                       :ref="'custom-filter'"
                       :table-key="localTableKey"
                       @initialize="initialize"
                       @filter-change="setFilter"
                       @set-page="setPage"></component>
        </div>
        <div v-else>
            <b-row v-if="filterOnEnter">
                <b-col>
                    <h5>Za iskanje kliknite na gumb "Iskanje" ali pritisnite ENTER</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-for="filterKey in Object.keys(filter || {})" :key="filterKey" lg="5">
                    <b-col v-if="(getFieldPropertyByKey(filterKey, 'filterType') || '').toLowerCase() === 'select'"
                           lg="12">
                        <value-select
                            :clearable="(getFieldPropertyByKey(filterKey, 'clearable') || false)"
                            :do-reduce="(getFieldPropertyByKey(filterKey, 'doReduce') || false)"
                            :initial-value="filter[filterKey]"
                            :multiple="(getFieldPropertyByKey(filterKey, 'multiple') || false)"
                            :options="getFieldPropertyByKey(filterKey, 'selectFilterValues')"
                            :placeholder="'Izberi ' + (getFieldPropertyByKey(filterKey, 'label') || '').toLowerCase()"
                            :reduce-by="(getFieldPropertyByKey(filterKey, 'reduceBy') || '')"
                            :selected-label="getFieldPropertyByKey(filterKey, 'selectedLabel' || 'name')"
                            @value-change="newFilter => filter[filterKey] = newFilter"
                        />
                    </b-col>
                    <b-col v-if="(getFieldPropertyByKey(filterKey, 'filterType') || '').toLowerCase() === 'date'"
                           lg="12">
                        <date-select
                            :key="filter[filterKey]"
                            :initial-value="filter[filterKey]"
                            :placeholder="'Išči po ' + (getFieldPropertyByKey(filterKey, 'label') || '').toLowerCase() + ' (DD.MM.LLLL)'"
                            @value-change="newFilter => filter[filterKey] = newFilter"
                        />
                    </b-col>
                    <b-col v-else lg="12">
                        <text-input v-if="filterKey"
                                    :debounce="1420"
                                    :initial-value="filter[filterKey]"
                                    :placeholder="'Išči po ' + (getFieldPropertyByKey(filterKey, 'label') || '').toLowerCase()"
                                    @value-change="newFilter => filter[filterKey] = newFilter"
                        />
                    </b-col>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="Object.keys(filter || {}).length" class="mb-2 text-right" sm="12">
                    <b-button v-if="filterOnEnter" variant="success" @click="forceRefreshTable">
                        Iskanje
                    </b-button>
                    <b-button class="ml-2" variant="primary" @click="clearFilter">
                        Počisti iskalnik
                    </b-button>
                </b-col>

                <b-col v-if="selectable" class="mb-2 text-left" sm="12">
                    <b-checkbox
                        :checked="selectedAll"
                        @change="toggleSelected">
                        {{ selectedAllText }}
                    </b-checkbox>
                </b-col>
            </b-row>
        </div>

        <div v-if="initialized">
            <b-table
                :key="uuid+'__'+(forceRefresh ? 'frTrue' : 'frFalse')"
                :ref="uuid"
                :api-url="apiUrl"
                :aria-controls="uuid"
                :busy="loading"
                :current-page.sync="currentPage"
                :empty-filtered-text="'Ni podatkov'"
                :fields="fields"
                :filter="filter"
                :filter-debounce="filterOnEnter ? 300000 : 420"
                :items="items"
                :per-page="perPage"
                :primary-key="primaryKey"
                :small="small"
                :table-class="tableClasses"
                :total-rows="totalRows"
                responsive
                show-empty
            >
                <template v-if="selectable" #cell(selected)="{ item }">
                    <template>
                        <span aria-hidden="true">
                            <b-checkbox :checked="checkIfRowSelected(item)" @change="onRowClicked(item)"/>
                        </span>
                    </template>
                </template>

                <template v-if="selectable" #head(selected)>
                    Izbrani
                </template>

                <template #cell(actions)="data">
                    <b-button v-for="(action, index) in actions" v-if="showAction(action, data)"
                              :key="index"
                              v-b-tooltip.hover.v-primary="action.name"
                              :variant="action.variant ? action.variant : 'flat-primary'"
                              class="table-action-button"
                              @click="action.onClick(data)">
                        <font-awesome-icon :icon="action.icon"/>
                    </b-button>
                </template>

                <template #cell()="data">
                    <template v-if="data.field.hasOwnProperty('formatAsHTML') && data.field.formatAsHTML">
                        <span v-html="data.value"></span>
                    </template>
                    <template v-else-if="data.field.hasOwnProperty('editable') && data.field.editable">
                        <div style="min-width: 142px;">
                            <text-input
                                v-if="data.field.editableProperties.type === 'text'"
                                :debounce="420"
                                :disabled="(data.field.editableProperties.disabled || false)"
                                :initial-value="data.value"
                                :size="'sm'"
                                @value-change="newValue => $emit('cell-value-change', { newValue: newValue, ...data})"/>
                            <value-select
                                v-if="data.field.editableProperties.type === 'select'"
                                :append-to-body="true"
                                :clearable="(data.field.editableProperties.clearable || false)"
                                :disabled="(data.field.editableProperties.disabled || false)"
                                :do-reduce="(data.field.editableProperties.doReduce || false)"
                                :initial-value="data.value"
                                :multiple="(data.field.editableProperties.multiple || false)"
                                :options="data.field.editableProperties.selectOptions"
                                :reduce-by="(data.field.editableProperties.reduceBy || '')"
                                :selected-label="(data.field.editableProperties.selectedLabel || 'name')"
                                @value-change="newValue => $emit('cell-value-change', { newValue: newValue, ...data})"
                            />
                            <b-form-checkbox
                                v-if="data.field.editableProperties.type === 'checkbox'"
                                v-model="data.value"
                                :disabled="disableField(data.field, data)"
                                @change="newValue => $emit('cell-value-change', { newValue: newValue, ...data})"/>
                        </div>
                    </template>
                    <div v-else-if="data.field.hasOwnProperty('customField') && data.field.customField !== null">
                        <component :is="data.field.customField" :data="data"></component>
                    </div>
                    <template v-else>
                        {{ data.value }}
                    </template>
                </template>
                <template #empty="scope">
                    <h4>Ni podatkov</h4>
                </template>
                <template #emptyFiltered="scope">
                    <h4>Ni podatkov</h4>
                </template>
            </b-table>
            <b-overlay :show="loading">
                <template #overlay>
                    <loader/>
                </template>
                <b-pagination
                    v-if="totalRows>perPage"
                    v-model="currentPage"
                    :aria-controls="uuid"
                    :per-page="perPage"
                    :total-rows="totalRows">
                    <template #page="{ page, active }">
                        <div v-if="active">
                            <b-form-input v-model="currentPage" class="pagination-input" debounce="500"
                                          size="sm"
                                          type="number" @focus="$event.target.select()"/>
                        </div>
                        <i v-else>{{ page }}</i>
                    </template>
                </b-pagination>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import Loader from '../loader/Loader.vue';
import { FilterKeys } from '@/owms/types/baseTableConfig';
import ValueSelect from '@/owms/components/select/ValueSelect';
import TextInput from '@/owms/components/input/TextInput';
import { mapActions } from 'vuex';
import { mapMutations } from 'vuex'

export default {
    components: {Loader, ValueSelect, TextInput},

    name: 'BaseTable',
    data() {
        return {
            uuid: this.$uuid.v1(),
            initialized: false,
            filter: {},

            forceRefresh: false,

            totalRows: 10,
            currentPage: 1,
            perPage: 10,

            loading: false,
            requestInProgress: false,
            newRequest: false,
            latestCtx: {},

            selectedRows: [],
        };
    },
    props: {
        fields: {
            type: Array,
            required: true,
        },
        ssr: {
            type: Boolean,
            default: false,
        },
        localItems: {
            type: Array,
            default: null,
        },
        apiUrl: {
            type: String,
            default: null,
        },
        actions: {
            type: Array,
            default: null,
        },
        primaryKey: {
            type: String,
            default: 'id',
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selectMode: {
            type: String,
            default: 'multi',
        },
        initialSelectedRows: {
            type: Array,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
        rowClasses: {
            type: String,
            default: '',
        },
        tableClasses: {
            type: String,
            default: '',
        },
        initialPerPage: {
            type: Number,
            default: 10,
        },
        tableKey: {
            type: String,
            default: null,
            validator: function (value) {
                return value === null || Object.values(FilterKeys).includes(value);
            },
        },
        cacheBust: {
            type: Boolean,
            default: true,
        },
        customFilter: {
            default: null,
        },
        filterOnEnter: {
            type: Boolean,
            default: false,
        },
    },
    async beforeMount() {
        if(this.customFilter !== null) return;

        const isInit = await this.isBTabInitialized(this.localTableKey);
        if(!isInit) {
            let filter = this.getDefaultFilter() || {};
            this.initBTab({key: this.localTableKey, filter: filter, perPage: this.initialPerPage});
        }

        await this.initialize();
    },
    mounted() {
        if (this.selectable) {
            this.fields.unshift('selected');
            if (this.initialSelectedRows !== null) {
                for (const item of this.initialSelectedRows) {
                    const index = this.initialSelectedRows.map(row => row[this.primaryKey]).indexOf(item[this.primaryKey]);
                    this.selectedRows.push(this.initialSelectedRows[index]);
                }
            }
        }
    },
    methods: {
        ...mapActions({
            isBTabInitialized: 'baseTable/isBTabInitialized',
            getBTabFilter: 'baseTable/getBTabFilter',
            getBTabSort: 'baseTable/getBTabSort',
            getBTabPagination: 'baseTable/getBTabPagination',
        }),
        ...mapMutations({
            initBTab: 'baseTable/INIT_B_TAB',
            setBTabFilter: 'baseTable/SET_B_TAB_FILTER',
            setBTabSort: 'baseTable/SET_B_TAB_SORT',
            setBTabPagination: 'baseTable/SET_B_TAB_PAGINATION',
        }),

        async initialize() {
            const tabPagination = await this.getBTabPagination(this.localTableKey);

            this.filter = await this.getBTabFilter(this.localTableKey);
            this.totalRows = tabPagination.totalRows;
            this.currentPage = tabPagination.currentPage;
            this.perPage = tabPagination.perPage;
            this.initialized = true;
        },

        refresh() {
            if((this.$refs[this.uuid] || null) !== null) this.$refs[this.uuid].refresh();
        },
        setFilter(filter) {
            this.filter = {...filter};
        },
        forceRefreshTable() {
            this.forceRefresh = !this.forceRefresh;
        },
        setPage(page) {
            this.currentPage = page;
        },

        getFieldPropertyByKey(key, property) {
            try {
                const field = this.fields.find(field => field.key === key);
                return field[property];
            } catch {
                return null;
            }
        },
        showAction(action, data) {
            if (action.hasOwnProperty('show')) return action.show(data);
            return true;
        },
        disableField(field, data) {
            if (field.hasOwnProperty('disabled')) return field.disabled(data);
            return false;
        },

        clearFilter() {
            for (const key in this.filter) {
                this.filter[key] = null;
            }
            if(this.filterOnEnter) this.forceRefreshTable();
        },
        getDefaultFilter() {
            let defaultFilter = {};
            const fields = this.fields.filter(field =>
                field.hasOwnProperty('key')
                && field.hasOwnProperty('filter')
                && field.filter);

            for (const field of fields) {
                defaultFilter[field.key] = null;
                if (field.hasOwnProperty('filterDefaultValue')) defaultFilter[field.key] = field.filterDefaultValue;
            }

            return defaultFilter;
        },

        /*RENDER DATA*/
        items(ctx, callback) {
            if (this.requestInProgress) {
                this.newRequest = true;
                this.latestCtx = ctx;
                return;
            }

            this.requestInProgress = this.loading = true;

            let p = null;
            if (this.ssr) p = (ctx) => this.renderServerItems(ctx);
            else p = (ctx) => this.renderLocalItems(ctx);

            this.setBTabFilter({key: this.localTableKey, filter: ctx.filter});
            this.setBTabSort({key: this.localTableKey, sort: {sortBy: ctx.sortBy, sortDesc: ctx.sortDesc}});
            let response = {};

            p(ctx)
                .then(r => {
                    response = r;
                })
                .catch(e => {
                    //TODO notify error
                    console.log(e);
                    response = { items: [], totalRows: 0, perPage: ctx.perPage, currentPage: 1 };
                })
                .finally(() => {
                    this.requestInProgress = this.loading = false;

                    if (this.newRequest) {
                        this.newRequest = false;
                        this.items(this.latestCtx, callback);
                    }
                    else {
                        //SET PAGINATION
                        const currentPage = (response.totalRows / response.perPage) <= (response.currentPage - 1) && response.currentPage > 1 ? 1 : response.currentPage;
                        const tabPagination = { totalRows: response.totalRows, perPage: response.perPage, currentPage: currentPage };
                        this.setBTabPagination({ key: this.localTableKey, pagination: tabPagination });

                        this.totalRows = tabPagination.totalRows;
                        this.currentPage = tabPagination.currentPage;

                        let items = [];
                        if(!this.ssr) items = response.items.slice((tabPagination.currentPage - 1) * tabPagination.perPage, tabPagination.currentPage * tabPagination.perPage);
                        else items = response.items;

                        callback(items);
                    }
                });
        },
        async renderLocalItems(ctx) {
            const isInit = await this.isBTabInitialized(this.localTableKey);
            if(!isInit) return {
                items: [],
                totalRows: 0,
                perPage: ctx.perPage,
                currentPage: 1,
            };

            let items = [...this.localItems];

            //DO FILTER
            let tabFilter = await this.getBTabFilter(this.localTableKey);

            if (tabFilter !== null) {
                tabFilter = this.objectRemoveNullAttr({...tabFilter});

                items = items.filter((item) => {
                    const fields = this.fields.filter(f => f.hasOwnProperty('filter') && f.filter && tabFilter.hasOwnProperty(f.key));
                    for (const field of fields) {
                        let value = this.objectValueByPath(item, field.key);

                        if (field.hasOwnProperty('prepareValueForFilter'))
                            value = (String(field.prepareValueForFilter(value))).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        else if (field.filterByFormatted)
                            value = (String(field.formatter(value))).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        else
                            value = (String(value)).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                        if (Array.isArray(tabFilter[field.key])) {
                            if (!tabFilter[field.key].includes(value)) return false;
                        }
                        else if (!value.includes(tabFilter[field.key])) return false;
                    }
                    return true;
                });
            }

            //DO SORT
            const tabSort = await this.getBTabSort(this.localTableKey);

            if (tabSort !== null) {
                items.sort((item1, item2) => {
                    const field = this.fields.find(f => f.key === tabSort.sortBy);
                    let v1 = this.objectValueByPath(item1, field.key), v2 = this.objectValueByPath(item2, field.key);

                    if (field.hasOwnProperty('sortByFormatted') && field.sortByFormatted) {
                        v1 = field.formatter(v1);
                        v2 = field.formatter(v2);
                    }

                    const v1IsN = this.isNumber(v1), v2IsN = this.isNumber(v2);

                    if (v1IsN && !v2IsN) return -1 * (tabSort.sortDesc ? -1 : 1);
                    if (v2IsN && !v1IsN) return 1 * (tabSort.sortDesc ? -1 : 1);
                    if (v1IsN && v2IsN) {
                        if (v1 < v2) return -1 * (tabSort.sortDesc ? -1 : 1);
                        if (v1 > v2) return 1 * (tabSort.sortDesc ? -1 : 1);
                        return 0;
                    }

                    v1 = (String(v1)).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    v2 = (String(v2)).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                    if (v1 < v2) return -1 * (tabSort.sortDesc ? -1 : 1);
                    if (v1 > v2) return 1 * (tabSort.sortDesc ? -1 : 1);
                    return 0;
                });
            }

            return {
                items: items,
                totalRows: items.length,
                perPage: ctx.perPage,
                currentPage: ctx.currentPage,
            };
        },
        async renderServerItems(ctx) {
            const isInit = await this.isBTabInitialized(this.localTableKey);
            if(!isInit) return {
                items: [],
                totalRows: 0,
                perPage: ctx.perPage,
                currentPage: 1,
            };

            let params = {};

            //ADD FILTER
            let tabFilter = await this.getBTabFilter(this.localTableKey);
            if (tabFilter !== null) {
                tabFilter = this.objectRemoveNullAttr({...tabFilter});
                params = {...tabFilter};
            }

            //ADD SORT
            const tabSort = await this.getBTabSort(this.localTableKey);
            if (tabSort !== null) {
                params.sortBy = tabSort.sortBy.replace('.', '_') + (tabSort.sortDesc ? '.desc' : '.asc');
            }

            //ADD PAGINATION
            if (ctx.perPage) {
                params.perPage = ctx.perPage;
                params.page = ctx.currentPage;
            }

            //ADD CACHE BUST
            if(this.cacheBust) params.cacheBust = (new Date).getTime();

            const response = await this.$http.get(ctx.apiUrl, {params: params});
            return {
                items:  response.data.data,
                totalRows: response.data.total,
                perPage: ctx.perPage,
                currentPage: ctx.currentPage,
            };
        },

        /*ROW SELECT*/
        checkIfRowSelected(item) {
            return this.selectedRows.some(x => x[this.primaryKey] === item[this.primaryKey]);
        },
        toggleSelected() {
            if (!this.selectedAll) {
                this.$refs[this.uuid].localItems.forEach(x => this.selectedRows.push(x));
            } else {
                this.selectedRows = [];
            }
            this.$emit('selected-row-change',this.selectedRows);
        },
        onRowClicked(item, index, event) {
            if (this.selectable) this.rowToggleSelect(item);
        },
        rowToggleSelect(item) {
            const index = this.indexWithinSelectedRows(item);
            if (index > -1)
                this.selectedRows.splice(index, 1);
            else
                this.selectedRows.push(item);
            this.$emit('selected-row-change',this.selectedRows);
        },
        indexWithinSelectedRows(item) {
            return this.selectedRows.findIndex(row => this.objectValueByPath(row, this.primaryKey) === this.objectValueByPath(item, this.primaryKey));
        },
        getSelectedRows() {
            if (this.selectable) return this.selectedRows;
            return null;
        },
    },
    computed: {
        selectedAllText() {
            return this.selectedRows.length ? 'Odstrani izbor' : 'Izberi vse';
        },
        selectedAll() {
            return !!this.selectedRows.length;
        },
        localTableKey() {
            return this.tableKey || this.uuid;
        },
    },
};
</script>

<style scoped>
.table-action-button {
    padding: 0px;
}

.pagination-input {
    max-width: 50px;
    max-height: 20px;
    text-align: center;
    -moz-appearance: textfield;
}
</style>
