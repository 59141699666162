import { Actions } from '~/libs/acl/actions';
import { Subjects } from '~/libs/acl/subjects';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('~/views/Login.vue'),
        meta: {
            layout: 'full',
            action: Actions.read,
            resource: Subjects.Guest,
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('~/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            action: Actions.read,
            resource: Subjects.Guest,
        },
    },

    {
        path: '/home',
        name: 'home',
        component: () => import('~/views/Home.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Auth,
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('~/views/notifications/Home.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Auth,
        },
    },
    {
        path: '/competitions',
        name: 'competitions',
        component: () => import('~/views/competitions/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.Competition,
        },
    },
    {
        path: '/competition/:id?',
        name: 'competitionAddEdit',
        component: () => import('~/views/competitions/AddEdit.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.Competition,
        },
    },
    {
        path: '/games',
        name: 'games',
        component: () => import('~/views/games/Home.vue'),
        meta: {
            action: Actions.update,
            resource: Subjects.Game,
        },
    },
    {
        path: '/games/:id',
        name: 'gamesEdit',
        component: () => import('~/views/games/Edit.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Game,
        },
    },
    {
        path: '/clubs',
        name: 'clubs',
        component: () => import('~/views/clubs/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.Club,
        },
    },
    {
        path: '/teams/:id',
        name: 'teams',
        component: () => import('~/views/teams/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.Team,
        },
    },
    {
        path: '/my-teams',
        name: 'my-teams',
        component: () => import('~/views/teams/MyTeams.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.MyTeam,
        },
    },
    {
        path: '/game-jersey',
        name: 'game-jersey',
        component: () => import('~/views/games/GameJerseyHome.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Jersey,
        },
    },
    {
        path: '/players/:id',
        name: 'players',
        component: () => import('~/views/players/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.PlayerTeam,
        },
    },
    {
        path: '/new-player/:id?',
        name: 'new-player',
        component: () => import('~/views/registrations/New.vue'),
        meta: {
            action: Actions.create,
            resource: Subjects.Registration,
        },
    },
    {
        path: '/review-player/:id',
        name: 'review-player',
        component: () => import('~/views/registrations/Review.vue'),
        meta: {
            action: Actions.create,
            resource: Subjects.RegistrationComity,
        },
    },
    {
        path: '/extend-player/:id',
        name: 'extend-player',
        component: () => import('~/views/registrations/Extend.vue'),
        meta: {
            action: Actions.create,
            resource: Subjects.Registration,
        },
    },
    {
        path: '/edit-player/:id',
        name: 'edit-player',
        component: () => import('~/views/players/Edit.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Registration,
        },
    },
    {
        path: '/registrations',
        name: 'registrations',
        component: () => import('~/views/registrations/Home.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Registration,
        },
    },
    {
        path: '/team-registrations',
        name: 'team-registrations',
        component: () => import('~/views/teamRegistration/Home.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Registration,
        },
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        component: () => import('~/views/tournaments/Home.vue'),
        meta: {
            action: Actions.read,
            resource: Subjects.Tournament,
        },
    },
    {
        path: '/exports',
        name: 'exports',
        component: () => import('~/views/exports/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('~/views/users/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.User,
        },
    },
    {
        path: '/seasons',
        name: 'seasons',
        component: () => import('~/views/seasons/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },
    {
        path: '/personnel',
        name: 'personnel',
        component: () => import('~/views/personnel/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },
    {
        path: '/sport-halls',
        name: 'sport-halls',
        component: () => import('~/views/sportHalls/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        component: () => import('~/views/myProfile/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.RefereeScope,
        },
    },
    {
        path: '/referees',
        name: 'referees',
        component: () => import('~/views/referees/Home.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },
    {
        path: '/competition-parts',
        name: 'competition-parts',
        component: () => import('~/views/competitions/Parts.vue'),
        meta: {
            action: Actions.manage,
            resource: Subjects.AdminScope,
        },
    },

    {
        path: '*',
        redirect: 'home',
    },
];

export default routes;
