export const FilterKeys = {
    allClubs: 'allClubs',
    allTeamPlayers: 'allTeamPlayers',
    allCompetitions: 'allCompetitions',
    newPlayersBase: 'newPlayersBase',
    renewDlPlayers: 'renewDlPlayers',
    allTeams: 'allTeams',
    availableTeamPlayers: 'availableTeamPlayers',
    allNotifications: 'allNotifications',
    freePlayersBase: 'freePlayersBase',
    allPlayersBase: 'allPlayersBase',
    renewOtherPlayersBase: 'renewOtherPlayersBase',
    activePlayersBase: 'activePlayersBaseV2',
    allTeamsTable: 'allTeamsTable',
    inactivePlayersTable: 'inactivePlayersTable',
    newPlayersTable: 'newPlayersTable',
    allGamesTable: 'allGamesTable',
    gameJerseyTable: 'gameJerseyTable',
    allUsers: 'allUsers',
    allSeasons: 'allSeasons',
    allPersonnelTypes: 'allPersonnelTypes',
    allPersonnel: 'allPersonnel',
    allSportHalls: 'allSportHalls',
    allReferees: 'allReferees',
    competitionParts: 'competitionParts',
    pendingInfoTeamsTable: 'pendingInfoTeamsTable',
};
